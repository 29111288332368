<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>App Selector</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <VPAvatar />
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item :to="{ name: 'account-settings' }">
                      <v-list-item-title>Account Settings</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout">
                      <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
              <v-progress-linear
                :active="this.$store.getters.showLoader"
                indeterminate
              ></v-progress-linear>
              <v-container>
                <p>
                  <strong>You have access available to multiple apps</strong>
                </p>
                <p>Please choose the app you wish to view:</p>
                <v-list>
                  <v-list-item
                    v-for="app in apps"
                    v-bind:key="app.name"
                    nav
                    :to="{ name: 'app-dashboard', params: { id: app.id } }"
                  >
                    <v-list-item-icon>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ app.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import VPAvatar from "@/views/components/VPAvatar";

export default {
  props: {
    apps: Array,
    userInitials: String,
  },

  components: {
    VPAvatar,
  },

  methods: {
    logout: function() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
